import React, { Component } from 'react';
import { Alert, Drawer, Form } from 'antd';
import FlightTimesEntryDrawer from 'components/flights/times/FlightTimesEntryDrawer';
import { useQuery } from 'react-apollo';

class FlightTimesEntryDrawerWrapper extends Component {
    state = {
        visible: false,
        flightID: null,
        refetch: null,
        editedFlight: null,
        selectedPaxCgo: [],
        selectedLegs: [],
        width:'60rem'
    }

    open = (flightID, refetch) => this.setState({ flightID, visible: true, refetch })
    close = () => this.setState({ flight:null, visible: false, refetch: null, editedFlight: null, selectedPaxCgo: [], selectedLegs: [], width: '60rem'})
    changeWidth = (width) => this.setState({width})
    editFlight = (editedFlight) => {
        editedFlight.legs = {}
        editedFlight.legsArray.forEach(leg => {
            var newLeg = {...leg}
            newLeg.paxIDs = newLeg.paxIDs.map(p => p._id)
            newLeg.cgoIDs = newLeg.cgoIDs.map(p => p._id)
            editedFlight.legs[leg.order] = newLeg
        })
        this.setState({editedFlight})
    }
    selectPaxCgo = (selectedPaxCgo) => this.setState({selectedPaxCgo})
    selectLegs = (selectedLegs) => this.setState({selectedLegs})
    reset = () => {
        this.props.form.resetFields()
        this.setState({ width:'60rem', editedFlight: null, selectedPaxCgo: [], selectedLegs: []})
    }
    render(){
        const { component: Component, options } = this.props;
        const drawerProps = options ? options.drawerProps : {};
        return (
            <React.Fragment>
                <Component {...this.props} flightDrawer={{
                    open: this.open,
                    close: this.close   
                }} />
                <Drawer
                    title="View Flight"
                    width={this.state.width}
                    visible={this.state.visible}
                    onClose={this.close}
                    {...drawerProps}
                >
                    <FlightTimesEntryDrawer 
                        onClose={(refetch) => {
                            if(refetch){
                                this.state.refetch();
                            }
                            this.close()
                        }}
                        flightID={this.state.flightID}
                        // flight={this.state.editedFlight ? this.state.editedFlight : this.state.flight}
                        editedFlight={this.state.editedFlight}
                        changeWidth={this.changeWidth}
                        editFlight={this.editFlight}
                        reset={this.reset} 
                        selectedPaxCgo={this.state.selectedPaxCgo}
                        selectedLegs={this.state.selectedLegs}
                        selectPaxCgo={this.selectPaxCgo}
                        selectLegs={this.selectLegs}
                        form={this.props.form}
                        editingFlight={this.state.editedFlight}
                        editMode={options.editMode}
                    />
                </Drawer>
            </React.Fragment>
        )
    }
}

const WithForm = Form.create()(FlightTimesEntryDrawerWrapper)
const withFlightTimesEntryDrawer = (options) => (Component) => (props) => (
    <WithForm {...props} options={options} component={Component} />
)

export default withFlightTimesEntryDrawer
import queryString from 'qs';
import React from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment';

export const getUniqueTransporters = contracts => {
    var uniqueTp = {};

    contracts.forEach(c => {
        uniqueTp[c.tpID._id] = c.tpID;
    })

    var sortedPaxList = Object.values(uniqueTp).sort((a, b) => {
        if (a.name < b.name){
            return -1
        }
        if (a.name > b.name){
            return 1
        }
        return 0
    })
    return sortedPaxList
}

export const getTransporter = (contracts, tpID) => {
    const tp = contracts.find(c => c.tpID._id === tpID)
    if (tp) {
        return tp.tpID
    }
    return undefined
}

export const setTpQueryRoute = (history, location, tpID) => {
    const qp = queryString.parse(location.search, { ignoreQueryPrefix: true });
    const search = {
        ...qp,
        tp: tpID
    }
    history.push({
        pathname: location.pathname,
        search: queryString.stringify(search)
    })
}

export const pushHistory = (props, pathname) => {
    const qp = queryString.parse(props.location.search, { ignoreQueryPrefix: true });
    const search = {
        tp: qp.tp
    }
    props.history.push({
        pathname: pathname,
        search: queryString.stringify(search)
    })
}

export const toDateTime = (iso, checkIsToday=true) => {
    var dateObj = new Date(iso);
    const hh = ('0' + dateObj.getHours()).slice(-2);
    const mm = ('0' + dateObj.getMinutes()).slice(-2);
    const ss = ('0' + dateObj.getSeconds()).slice(-2);

    const yy = dateObj.getFullYear();
    const nn = ('0' + dateObj.getMonth()).slice(-2);
    const dd = ('0' + dateObj.getDay()).slice(-2);

    const time = `${hh}:${mm}:${ss}`;
    const date = `${yy}-${nn}-${dd}`;

    if (checkIsToday && new Date(Date.now()).getDate() === dateObj.getDate()){
        return `${time} TODAY`;
    }
    return `${time} ${date}`;
}

export const sortObjects = (key, a, b) => {
    try{
        var nameA = a[key].toUpperCase(); // ignore upper and lowercase
        var nameB = b[key].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    }
    catch(TypeError) {
        return -1;
    }
}

export const filterNull = (list) => list.filter(item => item);

export const deDupe = (array, func) => {
    const keys = []
    const newArray = array.filter(element => {
        const funcVal = func(element)
        if (!keys.includes(funcVal)){
            keys.push(funcVal)
            return true
        }
        return false
    })
    return newArray
}

export const simpleSort = (array, func) => {
    return array.sort((a, b) => {
        const aFuncVal = func(a);
        const bFuncVal = func(b);
        if (aFuncVal < bFuncVal){
            return -1
        }
        if (aFuncVal > bFuncVal){
            return 1
        }
        return 0
    })
}

export const deDupeSort = (array, deDupeFunc, sortFunc) => simpleSort(deDupe(array, deDupeFunc), sortFunc)

export const YesNo = (bool) => {
    if (bool === true){
        return <span style={{color: 'green'}}>Yes</span>
    }
    else if (bool === false){
        return <span style={{color: 'red'}}>No</span>
    }else{
        return null
    }
}

export const get = (obj, func, def) => {
    try{
        return func(obj)
    }
    catch(error){
        if (error instanceof TypeError){
            return def
        }
        else{
            throw error
        }
    }
}

export const safeGet = (p, o) =>
    p.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, o)


export const getDestinationsFromLegs = (legs) => {
    var locations = [];
    legs.forEach((leg) => {
        locations.push({_id: leg.destinationID, name: leg.destination})
    })
    return locations;
}

export const getLocationsFromLegs = (legs) => {
    var locations = [];
    legs.forEach((leg) => {
        const departureIndex = locations.findIndex(p => p._id === leg.departureID);
        const destinationIndex = locations.findIndex(p => p._id === leg.destinationID);
        if (departureIndex === -1){
            locations.push({_id: leg.departureID, name: leg.departure})
        }
        if (destinationIndex === -1){
            locations.push({_id: leg.destinationID, name: leg.destination})
        }
    })
    return locations;
}

export const getDestinationsFromPaxList = (origin, passengers, cargo) => {
    var locations = []
    if(passengers){
        passengers.forEach(pax => {
            const destinationIndex = locations.findIndex(p => p._id === pax.destinationID._id);
            if (destinationIndex === -1 && pax.destinationID._id !== origin._id){
                locations.push(pax.destinationID)
            }

        })
    }
    if(cargo){
        cargo.forEach(pax => {
            const destinationIndex = locations.findIndex(p => p._id === pax.destinationID._id);
            if (destinationIndex === -1 && pax.destinationID._id !== origin._id){
                locations.push(pax.destinationID)
            }

        })
    }
    locations.push(origin);
    return locations;
}

export const getLocationsFromPaxAndCgoList = (origin, passengers, cargo) => {
    var locations = [
        origin
    ]
    if(passengers){
        passengers.forEach(pax => {
            const departureIndex = locations.findIndex(p => p._id === pax.departureID._id);
            const destinationIndex = locations.findIndex(p => p._id === pax.destinationID._id);
            if (departureIndex === -1){
                locations.push(pax.departureID)
            }
            if (destinationIndex === -1){
                locations.push(pax.destinationID)
            }
        })
    }

    if(cargo){
        cargo.forEach(cgo => {
            const departureIndex = locations.findIndex(p => p._id === cgo.departureID._id);
            const destinationIndex = locations.findIndex(p => p._id === cgo.destinationID._id);
            if (departureIndex === -1){
                locations.push(cgo.departureID)
            }
            if (destinationIndex === -1){
                locations.push(cgo.destinationID)
            }
        })
    }
    return locations;
}

export const getLocationsFromPaxList = (origin, passengers) => {
    var locations = [
        origin
    ]
    passengers.forEach(pax => {
        const departureIndex = locations.findIndex(p => p._id === pax.departureID._id);
        const destinationIndex = locations.findIndex(p => p._id === pax.destinationID._id);
        if (departureIndex === -1){
            locations.push(pax.departureID)
        }
        if (destinationIndex === -1){
            locations.push(pax.destinationID)
        }

    })
    return locations;
}

export const createNewLeg = (order, departure, destination) => {
    return {
        order: order,
        departureID: departure.key,
        departure: departure.label,
        destinationID: destination.key,
        destination: destination.label,
        paxIDs: [],
        cgoIDs: [],
        paxWeight: 0,
        bagWeight: 0,
        paxCount: 0,
        bagCount: 0,
        cgoWeight: 0,
        cgoCount: 0
    }
}

export const paxOnFlightLeg = (leg, paxSourceData, cgoSourceData) => {
    if ((!paxSourceData && !cgoSourceData) || (!paxSourceData.length && !cgoSourceData.length)){
        return {
            order: leg.order,
            departureID: leg.departureID,
            departure: leg.departure,
            destinationID: leg.destinationID,
            destination: leg.destination,
            paxIDs: [],
            cgoIDs: [],
            paxWeight: 0,
            bagWeight: 0,
            paxCount: 0,
            bagCount: 0,
            cgoWeight: 0,
            cgoCount: 0
        }
    }
    
    //Recalculate totals
    let paxIDs = [...new Set(paxSourceData.map(pax => pax._id))]
    let cgoIDs = [...new Set(cgoSourceData.map(cgo => cgo._id))]
    return {
        order: leg.order,
        departureID: leg.departureID,
        departure: leg.departure,
        destinationID: leg.destinationID,
        destination: leg.destination,
        paxIDs: paxIDs,
        cgoIDs: cgoIDs,
        paxWeight: paxSourceData.reduce((acc, { paxWeight=0 }) => acc + paxWeight, 0),
        bagWeight: paxSourceData.reduce((acc, { bagWeight=0 }) => acc + bagWeight, 0),
        paxCount: paxIDs.length,
        bagCount: paxSourceData.reduce((acc, { bagCount=0 }) => acc + bagCount, 0),
        cgoWeight: cgoSourceData.reduce((acc, { weight=0 }) => acc + weight, 0),
        cgoCount: cgoIDs.length
    }
}

export const locationListToFlightLegs = (locations, paxData, cgoData) => {
    const getID = (field) => typeof field === 'string' ? field : field._id
    var legs = [];
    locations.forEach((loc, pathIndex) => {
        if(pathIndex === locations.length -1){
            return;
        }
        const passengers = paxData.filter(pax => {
            const departureIdx = locations.findIndex(p => (p.key || getID(p)) === getID(pax.departureID))
            const destinationIdx = locations.slice().reverse().findIndex(p => (p.key || getID(p)) === getID(pax.destinationID))
            var count = locations.length - 1
            var finalDestIndex = destinationIdx >= 0 ? count - destinationIdx : destinationIdx;           
            const isOnLeg = pathIndex >= departureIdx && pathIndex < finalDestIndex;
            return isOnLeg;
        })
        const cargo = cgoData.filter(cgo => {
            const departureIdx = locations.findIndex(p => (p.key || getID(p)) === getID(cgo.departureID))
            const destinationIdx = locations.slice().reverse().findIndex(p => (p.key || getID(p)) === getID(cgo.destinationID))
            var count = locations.length - 1
            var finalDestIndex = destinationIdx >= 0 ? count - destinationIdx : destinationIdx;
            const isOnLeg = pathIndex >= departureIdx && pathIndex < finalDestIndex;
            return isOnLeg;
        })
        const nextLoc = locations[pathIndex + 1] || locations[locations.length-1];
        const leg = {
            order: pathIndex,
            departureID: loc.key || getID(loc),
            departure: loc.label || loc.name,
            destinationID: nextLoc.key || getID(nextLoc),
            destination: nextLoc.label || nextLoc.name,
            paxIDs: passengers,
            cgoIDs: cargo,
            paxWeight: passengers.reduce((acc, { paxWeight=0 }) => acc + paxWeight, 0),
            bagWeight: passengers.reduce((acc, { bagWeight=0 }) => acc + bagWeight, 0),
            paxCount: passengers.length,
            bagCount: passengers.reduce((acc, { bagCount=0 }) => acc + bagCount, 0),
            cgoWeight: cargo.reduce((acc, { weight=0 }) => acc + weight, 0),
            cgoCount: cargo.length
        }
        legs.push(leg)
    })
    return legs
}

/**
 * Converts flight legs to a paxList and cgoList
 * @param {Array<any>} legs 
 * @param {(paxID: string) => string} getPaxDestIDCb
 * @param {(cgoID: string) => string} getCgoDestCb
 * @returns {{ paxIDs: Array<string>, cgoIDs: Array<string> }}
 */
export const flightLegsToPaxCgoLists = (legs, getPaxDestIDCb, getCgoDestIDCb) => {

    if (!legs){
        return { paxIDs: [], cgoIDs: [] }
    }

    let paxList = [];
    let cgoList = [];

    /**
     * @param {Array<string>} list 
     * @param {string} id 
     */
    function insert(list, id){
        if (list.findIndex(i => i===id) > -1){
            return;
        }
        else
        {
            list.push(id)
        }
    }

    /**
     * @param {Array<string>} list 
     * @param {Array<string>} ids 
     */
    function insertAll(list, ids){
        ids.forEach((id) => insert(list, id))
    }

    function sortPaxCurrDestFirst(leg){
        if (!getPaxDestIDCb) return;

        leg.paxIDs.sort((a, b) => {
            let aDest = getPaxDestIDCb(a);
            let bDest = getPaxDestIDCb(b);
            if (aDest === bDest){
                return 0;
            }
            else if (bDest === leg.destinationID)
            {
                return 1;
            }
            else if (aDest === leg.destinationID){
                return -1;
            }
        })
    }

    function sortCgoCurrDestFirst(leg){
        if (!getCgoDestIDCb) return;

        leg.cgoIDs.sort((a, b) => {
            let aDest = getCgoDestIDCb(a);
            let bDest = getCgoDestIDCb(b);
            if (aDest === bDest){
                return 0;
            }
            else if (bDest === leg.destinationID)
            {
                return 1;
            }
            else if (aDest === leg.destinationID){
                return -1;
            }
        })
    }

    legs.forEach(leg => {
        insertAll(paxList, leg.paxIDs)
        insertAll(cgoList, leg.cgoIDs)
        sortPaxCurrDestFirst(leg);
        sortCgoCurrDestFirst(leg);
    })

    return {
        paxIDs: paxList,
        cgoIDs: cgoList
    }
}

export const removePaxCgoFromFlightLegs = (legs, paxIDs, cgoIDs) => {
    let newLegs = {...legs};

    newLegs.forEach((leg) => {
        paxIDs.forEach((paxID) => {
            let paxIdx = leg.paxIDs.findIndex(id => id === paxID)
            if (paxIdx > -1){
                leg.paxIDs.splice(paxIdx, 1);
            }
        })
        cgoIDs.forEach((cgoID) => {
            let cgoIdx = leg.cgoIDs.findIndex(id => id === cgoID)
            if (cgoIdx > -1){
                leg.cgoIDs.splice(cgoIdx, 1);
            }
        })
    })

    newLegs.filter((leg) => {
        let empty = !leg.cgoIDs && !leg.paxIDs;
        if (empty){
            return 0;
        }
        return 1;
    })

    return newLegs
}

export const paxListToFlightLegs = (origin, paxSourceData, cgoSourceData, locations) => {
    if ((!paxSourceData && !cgoSourceData) || (!paxSourceData.length && !cgoSourceData.length)){
        return [{
            order: 0,
            departureID: origin._id,
            departure: origin.name,
            destinationID: origin._id,
            destination: origin.name,
            paxIDs: [],
            cgoIDs: [],
            paxWeight: 0,
            bagWeight: 0,
            paxCount: 0,
            bagCount: 0,
            cgoWeight: 0,
            cgoCount: 0
        }]
    }
    var flightpath = [
        origin
    ]
    if(locations && locations.length > 0){
        flightpath = flightpath.concat(locations.slice(0, -1))
    }
    if(paxSourceData){
        paxSourceData.forEach(pax => {
            var departureIndex = flightpath.findIndex(p => p._id === pax.departureID._id);
            if (departureIndex === -1){
                flightpath.push(pax.departureID)
                departureIndex = flightpath.findIndex(p => p._id === pax.departureID._id);
            }
            const destinationIdx = flightpath.slice().reverse().findIndex(p => p._id === pax.destinationID._id)
            var count = flightpath.length - 1
            var finalDestIndex = destinationIdx > 0 ? count - destinationIdx : destinationIdx;
            if (finalDestIndex === -1 || ((finalDestIndex < departureIndex) && finalDestIndex !== 0)){
                flightpath.push(pax.destinationID)
            }
        })
    }
    if(cgoSourceData){
        cgoSourceData.forEach(pax => {
            var departureIndex = flightpath.findIndex(p => p._id === pax.departureID._id);
            if (departureIndex === -1){
                flightpath.push(pax.departureID)
                departureIndex = flightpath.findIndex(p => p._id === pax.departureID._id);
            }
            const destinationIdx = flightpath.slice().reverse().findIndex(p => p._id === pax.destinationID._id)
            var count = flightpath.length - 1
            var finalDestIndex = destinationIdx > 0 ? count - destinationIdx : destinationIdx;
            if (finalDestIndex === -1 || ((finalDestIndex < departureIndex) && finalDestIndex !== 0)){
                flightpath.push(pax.destinationID)
            }
        })
    }
    flightpath.push(origin)
    
    var legs = [];
    flightpath.forEach((loc, pathIndex) => {
        if(pathIndex === flightpath.length -1){
            return;
        }
        const passengers = paxSourceData.filter(pax => {
            const departureIdx = flightpath.findIndex(p => p._id === pax.departureID._id)
            const destinationIdx = flightpath.slice().reverse().findIndex(p => p._id === pax.destinationID._id)
            var count = flightpath.length - 1
            var finalDestIndex = destinationIdx >= 0 ? count - destinationIdx : destinationIdx;           
            const isOnLeg = pathIndex >= departureIdx && pathIndex < finalDestIndex;
            return isOnLeg;
        })
        const cargo = cgoSourceData.filter(cgo => {
            const departureIdx = flightpath.findIndex(p => p._id === cgo.departureID._id)
            const destinationIdx = flightpath.slice().reverse().findIndex(p => p._id === cgo.destinationID._id)
            var count = flightpath.length - 1
            var finalDestIndex = destinationIdx >= 0 ? count - destinationIdx : destinationIdx;
            const isOnLeg = pathIndex >= departureIdx && pathIndex < finalDestIndex;
            return isOnLeg;
        })
        const nextLoc = flightpath[pathIndex + 1] || origin;
        const leg = {
            order: pathIndex,
            departureID: loc._id,
            departure: loc.name,
            destinationID: nextLoc._id,
            destination: nextLoc.name,
            paxIDs: passengers.map(pax => pax._id),
            cgoIDs: cargo.map(cgo => cgo._id),
            paxWeight: passengers.reduce((acc, { paxWeight=0 }) => acc + paxWeight, 0),
            bagWeight: passengers.reduce((acc, { bagWeight=0 }) => acc + bagWeight, 0),
            paxCount: passengers.length,
            bagCount: passengers.reduce((acc, { bagCount=0 }) => acc + bagCount, 0),
            cgoWeight: cargo.reduce((acc, { weight=0 }) => acc + weight, 0),
            cgoCount: cargo.length
        }
        legs.push(leg)
    })
    return legs
}

export const getFlightDeparture = (flight, passengers=[], cargo=[]) => {
    if (!flight) return undefined;
    const paxList = [...flight.paxIDList, ...passengers];
    const cgoList = [...flight.cgoIDList, ...cargo];
    const legs = Object.values(JSON.parse(flight.legs || '{}'));
    var departure;
    if (!departure && legs.length){
        const leg = legs[0]
        departure = {
            _id: leg.departureID,
            name: leg.departure
        }
    }
    if (!departure && flight.departureID){
        departure = flight.departureID;
    }
    if (!departure && paxList.length){
        const pax = paxList.find(pax => pax && pax.departureID);
        if (pax){
            departure = pax.departureID;
        }
    }  
    if (!departure && cgoList.length){
        const cgo = cgoList.find(cgo => cgo && cgo.departureID);
        if (cgo){
            departure = cgo.departureID;
        }
    }
    if(!departure && flight.lastKnownController){
        departure = flight.lastKnownController
    }
    return departure
}

export const getMaxScheduledOrder = (paxList) => {
    const scheduledOrders = paxList.map(pax => {
        var so = (pax && pax.scheduledOrder) || 0
        if (typeof so !== 'number'){
            so = Number(so);
        }
        return so
    })
    .filter(so => so !== undefined && so !== null);

    if (!scheduledOrders.length) return null;

    return Math.max(...scheduledOrders);
}

export const calculateScheduledOrder = (paxList) => {
    const sortedPaxList = paxList.map(pax => {
        var so = pax.scheduledOrder || 0;
        if (typeof pax.scheduledOrder !== "number"){
            so = Number(so); 
        }
        return {
            ...pax,
            scheduledOrder: so
        };
    }).sort((a, b) => {
        return a.scheduledOrder - b.scheduledOrder
    });
    return sortedPaxList.map((pax, i) => {
        return {
            ...pax,
            scheduledOrder: i
        }
    })
}

export const getLabelInValue = (key, label, renderLabel) => (obj) => {
    if (!obj) return;
    let l;
    if (typeof renderLabel === 'function'){
        l = obj && renderLabel(obj);
    }
    else{
        l = label;
    }
    return {
        key: obj[key],
        label: l
    }
}

export const getContractName = (contract) => {
    if(!contract || !contract.name){
        return undefined
    }
    return `${contract.name}`
}

export const getPilotName = (pilot, firstNameFirst=true) => {
    if (!pilot || !pilot.name){
        return null
    }
    if (firstNameFirst){
        return `${pilot.name.lastName}, ${pilot.name.firstName}`
    }
    return `${pilot.name.lastName}, ${pilot.name.firstName}`
}

export const toFormField = (data, field, getValue) => {
    let value = data && data[field];
    if (typeof getValue === 'function'){
        value = getValue(value)
    }
    return {
        value
    }
}

export const mergeDataWithFormFields = (data, formFields) => {
    if (typeof data !== 'object') return;
    const dataEntries = Object.entries(data);
    let newFormFields = {};
    let i;
    for (i = 0; i < dataEntries.length; i++){
        const [ key, value ] = dataEntries[i];
        const field = (formFields && formFields[key]) || {};
        newFormFields[key] = { ...field, value };
    }
    return newFormFields;
}

export function parsePersonName(value){
    const inputString = ('' + value).trim();
        if (!inputString){
            return
        }
        var lastName, firstName;
        if (inputString.includes(", ")){
            const splitString = inputString.split(", ");
            if (splitString.length === 2){
                lastName = splitString[0];
                firstName = splitString[1];
            }
        }
        else if (inputString.includes(" ")){
            const splitString = inputString.split(" ");
            if (splitString.length === 2){
                firstName = splitString[0];
                lastName = splitString[1];
            }
        }
        if (!lastName && !firstName){
            return { lastName: null, firstName: null, name: inputString }
        }
        if (lastName && firstName){
            return { lastName, firstName, name: undefined }
        }
}

export function getOrgISNName(org){
    if (!org) return;
    const name = org.name;
    var isnName = org.isnName;
    if (!isnName){
        if (typeof name === 'string'){
            isnName = name.toLowerCase().replace(' ', '_');
        }else{
            isnName = name;
        }
    }
    return isnName
}

export function removeTypename(obj, recursive=true){
    if (!_.isObject(obj)){
        return obj;
    }

    if (Array.isArray(obj)){
        return obj.map(element => removeTypename(element));
    }
    // let keys = Object.keys(obj);
    // for (let index = 0; index < keys.length; index++) {
    //     let key = keys[index];
    //     let value = obj[key];
    //     if (value && typeof value === 'object'){
    //         delete value.__typename;
    //         if (recursive){
    //             removeTypename(value);
    //         }
    //     }
    // }
    let newObj = _.omit(obj, '__typename');
    if (recursive){
        newObj = _.mapValues(newObj, (value) => removeTypename(value, recursive))
    }
    return newObj
}

export function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function englishList(strings){
    return strings.join(', ').replace(/, ([^,]*)$/, ' and $1')
}

export function castArray(obj){
    let array = [];
    if (Array.isArray(obj)){
        array = obj;
    }
    else if (obj === undefined){
        return [];
    }
    else if (obj)
    {
        array.push(obj);
    }
    return array
}

export function cleanGraphQLErrorMsg(message){
    if (typeof message !== 'string'){
        message = String(message);
    }
    message = message.replace('GraphQL error: ', '');
    return message;
}

export function classNames(){

    let clsArray = [];

    for (const idx in arguments) {
        let arg = arguments[idx];

        if (Array.isArray(arg)) {
            clsArray = [ ...clsArray, ...arg ];
        }
        else if (typeof arg === 'string'){
            clsArray = [ ...clsArray, ...arg.split(' ') ];
        }
    }

    clsArray = clsArray.map(str => str.trim()).filter(str => str.length > 0);
    return clsArray.join(' ')
}

export function paxHasRestriction(pax){
    if (!pax.authorization){
        return false;
    }
    let entries = Object.entries(pax.authorization);
    entries = entries.filter(([key]) => {
        if (String(key).startsWith("brdRestriction_")){
            return true;
        }
        return false;
    })
    for (const i in entries) {
        if (entries[i] && entries[i][1].status === 'NEEDS_OVERRIDE'){
            return true;
        }
    }
    return false;
}

export function omit(obj, ...keys){
    if (typeof obj === 'object' && Array.isArray(keys)){
        let objCopy = {...obj};
        keys.forEach(key => {
            if (key in objCopy){
                delete objCopy[key];
            }
        })
        return objCopy;
    }
    return obj;
}

export function getPersonFullName(person){
    if (person && person.lastName && person.firstName){
        return `${person.lastName}, ${person.firstName}`
    }
    return undefined;
}
export function padStringInt(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export function momentOrNull(value){
    if (value){
        return moment(value)
    }
    return null
}

export function isPaxNodeLockedOrDeparted(pax){
    console.log(pax, pax?.currentCarrierID?.state)
    if (['LOCKED', 'DEPARTED'].includes(pax?.currentCarrierID?.state)){
        return true
    }
    return false
}

export function getFirstElArrayOrNull(arr){
    if (!arr || arr.length === 0){
        return undefined
    }
    return arr[0];
}

/**
 * @param {Moment} start 
 * @param {Moment} end 
 */
export function getMinuteDuration(start, end){
    let sMin = Math.floor(moment(start).unix() / 60);
    let eMin = Math.floor(moment(end).unix() / 60);

    return eMin - sMin;
}

/**
 * @param {Moment} start 
 * @param {Moment} end 
 */
 export function getMinuteDurationAsHrMin(start, end){
    let minDur = getMinuteDuration(start, end);

    let hours = padStringInt(Math.floor(Math.abs(minDur / 60)), 2);
    let minutes = padStringInt(Math.floor(Math.abs(minDur % 60)), 2);

    if (minDur < 0) {
        return "-" + hours + ':' + minutes
    }

    return hours + ':' + minutes
}

export function getDurationHoursMinutes(diff, formatFn){

    let minDur = Math.ceil(moment.duration(diff).asMinutes());
    
    let hours, minutes;
    
    hours = padStringInt(Math.floor(Math.abs(minDur / 60)), 2);
    minutes = padStringInt(Math.floor(Math.abs(minDur % 60)), 2);

    if (typeof formatFn === 'function'){
        return formatFn(hours, minutes)
    }

    if (minDur < 0) {
        return "-" + hours + ':' + minutes
    }

    return hours + ':' + minutes
}

// Pads a time (two or one digit) with a 0 if it is one digit
export function padTime(t){
    return String(t).padStart(2, '0')
}

// customOffset is in minutes. e.g. CST: -360
export function dateToZulu(date, customOffset){
    if (!date) return null
    let mom = moment(date);
    mom.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
    })
    if (customOffset){
        mom.utcOffset(customOffset);
    }
    let utc = moment.utc(mom, true).format();
    return utc
}

export function dateToZuluTzName(date, tzName){
    if (!date) return null
    let mom = moment(date);
    let day = mom.startOf('day');
    mom = moment.tz(mom.format(), tzName);
    mom.set({ day: day.day() })
    let utc = mom.format();
    return utc
}

export function getDateMidnightZulu(date, offset){
    if (!date) return null
    let day = moment(date).format('YYYY-MM-DD') + 'T00:00:00Z';
    let startTime = moment(day).add(offset, 'hours').utc().format()
    return startTime
}

export function dateMtimeMtoZuluStr(date, time){
    let d = moment.utc(moment(date)).format()
    let t = moment.utc(moment(time)).format();

    let newDate = d.split("T")[0]
    let newTime = t.split("T")[1]
    return newDate + "T" + newTime
}

/**
 * Converts the object { date: Moment, time: Moment } to a plain moment object.
 * Takes the date from the date, and the time from the time, and combines them together
 * @param {{ date: Moment, time: Moment }} dt 
 * @param {bool} nullIfPartial Returns null if obj only contains a date or only contains a time
 * @returns Moment
 */
export function dateMtimeMtoMoment(dt, nullIfPartial=true){
    if (!dt) return null
    let { date, time } = dt;
    if (!date && !time) return null;
    if (nullIfPartial && (!date || !time)){
        return null
    }
    let d = moment(date);
    let t = moment(time);

    d.set({
        hours: t.get('hours'),
        minutes: t.get('minutes'),
        seconds: t.get('seconds'),
        milliseconds: t.get('milliseconds')
    })
    
    return d
}

export function detectInvalidDate(date, valueIfInvalid){
    if (typeof date === 'string' && date.includes('Invalid date')){
        return valueIfInvalid;
    }
    return date;
}

/**
 * Checks if an arbritrary time is within the start time and end time of an unsorted list
 * @param {Moment} t Time to compare
 * @param {Array<[Moment, Moment]>} timeRanges List of arrays of size 2. Each entry has a start time and end time
 */
export function timeInTimeRanges(t, timeRanges){
    if (!Array.isArray(timeRanges)){
        return false;
    }
    let start, end, range;
    for (range in timeRanges){
        if (Array.isArray(range) && range.length > 1){
            start = range[0];
            end = range[1];
        }
        else continue;
        
        if (moment(t).isBetween(moment(start), moment(end))){
            return true
        }
    }
}

/**
 * Detects overlapping intervals in any number of intervals of Moment objects
 * @param {Array<[Moment, Moment]>} intervals 
 * @returns {bool} true if overlap, false if not
 */
export function momentIntervalsHaveOverlap(intervals){
    // Sort by start time
    // For each time range, check if the end time of the last entry is greater than the end time of the current entry
    // If true, then there is an overlap
    if (!Array.isArray(intervals)){
        return false;
    }
    if (intervals.length < 2){
        return false;
    }
    let sorted = intervals.sort((a, b) => a[0].diff(b[0]))
    for (let i = 1; i < sorted.length; i++){
        if (sorted[i-1][1].isAfter(sorted[i][0])){
            console.debug('[momentIntervalsHaveOverlap] Overlap detected.', sorted);
            console.debug(`${sorted[i-1][1]} of interval index ${i-1} is after ${sorted[i][0]} of interval index ${i}`);
            return true
        }
    }
    console.debug('[momentIntervalsHaveOverlap] No overlap detected.', sorted);
    return false
}

/**
 * Detects overlapping intervals in any number of intervals of Moment objects.
 * If an overlap is detected it will return a mapping with the left side representing
 * the index of the interval, and the right representing the interval element (start or end) -> (0 or 1)
 * 
 * Example: This return value says that the second and third interval are overlapping at the end time of the second interval
 * and the start time of the third interval:
 * 
 * {
 *      2: 1, // Second interval overlapping at end time
 *      3: 0  // Third interval overlapping at start time
 * }
 * 
 * @param {Array<[Moment, Moment]>} intervals 
 * @returns {Map<number, number>} Map where left is the offending interval index and right is the start (0) or end (1) that is overlapping
 */
export function getMomentIntervalsWithOverlaps(intervals){
    // Sort by start time
    // For each time range, check if the end time of the last entry is greater than the end time of the current entry
    // If true, then there is an overlap
    if (!Array.isArray(intervals)){
        return new Map();
    }
    if (intervals.length < 2){
        return new Map();
    }
    let sorted = intervals.sort((a, b) => a[0].diff(b[0]))

    let overlaps = new Map();

    for (let i = 1; i < sorted.length; i++){
        if (sorted[i-1][1].isAfter(sorted[i][0])){
            console.debug('[getMomentIntervalsWithOverlaps] Overlaps detected.', sorted);
            console.debug(`${sorted[i-1][1]} of interval index ${i-1} is after ${sorted[i][0]} of interval index ${i}`);
            overlaps.set(i-1, 1);
            overlaps.set(i, 0);
        }
    }
    if (overlaps.length === 0){
        console.debug('[getMomentIntervalsWithOverlaps] No overlaps detected.', sorted);
    }
    return overlaps
}

export function zeroTimeMoment(moment){
    moment.set({
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
    })
    return moment
}

export function maxTimeMoment(moment){
    moment.set({
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 999
    })
    return moment
}

/**
 * 
 * @param {string} str 
 * @param {boolean} lowercaseRest 
 * @param {RegExp} regexException Do not apply capitalization to a string that matches the pattern
 * @returns str
 */
export function capitalize(str, lowercaseRest=false, regexException=null){
    if (!str){ return '' }

    if (regexException && regexException.test(str)){
        return str
    }

    let firstLetter = String(str).charAt(0).toUpperCase();
    let rest = String(str).slice(1);
    if (lowercaseRest){
        rest = rest.toLowerCase();
    }
    return firstLetter + rest
}

/**
 * 
 * @param {string} str 
 * @param {boolean} lowercaseRest 
 * @param {RegExp} regexException Do not apply capitalization to a word that matches the pattern
 * @returns str
 */
export function capitalizeWords(str, lowercaseRest=false, regexException=null){
    if (!str){ return '' }
    
    let start = 0;
    let end = 0;
    let newStr = str;

    function write(){
        let word = str.substring(start, end);
        word = capitalize(word, lowercaseRest, regexException);
        
        let tmp = newStr.split('');
        tmp.splice(start, end-start, ...word.split(''));
        newStr = tmp.join('');
        start = end+1;
    }

    for (let i = 0; i < str.length; i++) {
        const char = str[i];
        if (char === " "){
            end = i;
            write();
        }
        if (i === str.length-1){
            end = i+1;
            write();
        }
    }

    return newStr;
}

export function getReportCriteriaValue(reportDocument, key){
    let criteria = castArray(reportDocument?.criteria);
    let c_item = criteria.find(c => c.key === key);

    if (c_item?.value){
        try{
            return JSON.parse(c_item.value);
        }
        catch(e){
            console.error("Failed to parse criteria value:", e);
            return undefined;
        }
    }

    return;
}

export function getDegAngleFromPoints(x1, y1, x2, y2){
    return Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;
}